import React from 'react'
import { useSelector } from 'react-redux'
import { Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

import { updateFuturePayment } from '../services'
import { handleError } from '../../../helpers'

export default function FuturePaymentsTable({ futurePayments, reload, pagination, setPagination }) {
	const currentUser = useSelector(state => state.auth.user)

	const columns = [
		{
			title: 'Consecutivo Interno',
			dataIndex: 'id',
		},
		{
			title: 'Proveedor',
			dataIndex: 'supplier',
			render: t => t.name
		},
		{
			title: 'NIT',
			dataIndex: 'supplier',
			render: t => t.document
		},
		{
			title: 'Fecha Programación',
			dataIndex: 'schedule_at',
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							{ !r.voided_at ? (
								<Popconfirm
									title="¿Desea anular esta programación?"
									okText="Anular"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => handleToggle(r)}
								>
									<Tooltip title="Anular" className='text-link'>
										<FontAwesomeIcon icon={faTimesCircle} />{" "}
										Anular
									</Tooltip>
								</Popconfirm>
							) : (
								<Popconfirm
									title="¿Desea habilitar esta programación?"
									okText="Habilitar"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => handleToggle(r)}
								>
									<Tooltip title="Habilitar" className='text-link'>
										<FontAwesomeIcon icon={faCheckCircle} />{" "}
										Habilitar
									</Tooltip>
								</Popconfirm>
							)}
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const handleToggle = futurePayment => {
		updateFuturePayment(futurePayment.id, {
			voided_at: futurePayment.voided_at ? '' : moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={futurePayments}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!futurePayments}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}