import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ActivityLogsList from './ActivityLogsList'

export default function ActivityLogs() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/activity_logs" component={ActivityLogsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}