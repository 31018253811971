import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import TicketHintForm from './TicketHintForm'

import { storeTicketHint } from '../services'
import { handleError } from '../../../helpers'

export default function NewTicketHintModal({ visible, onClose, reload, ticketType }) {
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: {
			ticket_type_id: ticketType.id,
			order: 10
		}
	})

	const onSubmit = values => {
		setLoading(true)
		storeTicketHint(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Respuesta Predefinida</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<TicketHintForm 
							register={register}
							errors={errors}
						/>
						<Button color='primary' type="submit" disabled={loading}>
							{loading && <Spinner animation='border' size="sm" className='mr-5' />}
							Agregar Respuesta Predefinida
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}