import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Badge } from 'reactstrap'
import { Divider, Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckToSlot, faCircleXmark, faComments, faFolderOpen, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import Pagination from '../../../components/Pagination'
import TicketNotesModal from '../../TicketNotes/partials/TicketNotesModal'

import { deleteTicket, updateTicket } from '../services'
import { handleError } from '../../../helpers'
import EditTicketEmailModal from './EditTicketEmailModal'

export default function TicketsTable({ tickets, reload, pagination, setPagination }) {
	const currentUser = useSelector(state => state.auth.user)
	const [notesModal, setNotesModal] = useState(null)
	const [editEmailModal, setEditEmailModal] = useState(null)
	
	const columns = [
		{
			title: 'ID Ticket',
			dataIndex: 'id',
		},
		{
			title: 'Proveedor',
			dataIndex: 'supplier',
			render: t => t.name
		},
		{
			title: 'NIT',
			dataIndex: 'supplier',
			render: t => t.document
		},
		{
			title: 'Categoria',
			dataIndex: 'ticket_type',
			render: t => t.name
		},
		{
			title: 'Correo de Notificación',
			dataIndex: 'active_notification_email',
			render: t => t ? t : <em>Sin registro</em>
		},
		{
			title: 'Fecha Radicación',
			dataIndex: 'created_at',
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			width: '187px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Correo de Notificación Spring" className='text-link' onClick={() => setEditEmailModal(r)}>
						<FontAwesomeIcon icon={faUserEdit} />
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Comentarios" className='text-link' onClick={() => setNotesModal(r)}>
						<FontAwesomeIcon icon={faComments} />{" "}
						({r.ticket_notes_count})
					</Tooltip>
					{ !r.voided_at && (
						<React.Fragment>
							{ !r.resolved_at ? (
								<React.Fragment>
									<Divider type="vertical" />
									<Popconfirm
										title="¿Desear marcar como resuelto esta solicitud?"
										okText="Marcar como Resuelto"
										cancelText="Cancelar"
										okButtonProps={{ danger:true }}
										placement="bottom"
										onConfirm={() => handleUpdate(r.id, 'resolved_at', moment().format('YYYY-MM-DD HH:mm:ss'))}
									>
										<Tooltip title="Marcar como Resuelto" className='text-primary'>
											<FontAwesomeIcon icon={faCheckToSlot} />
										</Tooltip>
									</Popconfirm>
								</React.Fragment>
							) : (
								<React.Fragment>
									<Divider type="vertical" />
									<Popconfirm
										title="¿Desear marcar como abierto esta solicitud?"
										okText="Marcar como Abierto"
										cancelText="Cancelar"
										okButtonProps={{ danger:true }}
										placement="bottom"
										onConfirm={() => handleUpdate(r.id, 'resolved_at', '')}
									>
										<Tooltip title="Marcar como Abierto" className='text-primary'>
											<FontAwesomeIcon icon={faFolderOpen} />
										</Tooltip>
									</Popconfirm>
								</React.Fragment>
							)}
						</React.Fragment>
					)}
					{ !r.voided_at && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm
								title="¿Desear cancelar esta solicitud?"
								okText="Anular Solicitud"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								placement="bottom"
								onConfirm={() => handleUpdate(r.id, 'voided_at', moment().format('YYYY-MM-DD HH:mm:ss'))}
							>
								<Tooltip title="Cancelar" className='text-primary'>
									<FontAwesomeIcon icon={faCircleXmark} />
								</Tooltip>
							</Popconfirm>
						</React.Fragment>
					)}
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm
								title="Esta acción no se puede repartir"
								okText="Eliminar Solicitud"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								placement="bottom"
								onConfirm={() => handleDelete(r.id)}
							>
								<Tooltip title="Eliminar" className='text-link'>
									<FontAwesomeIcon icon={faTrash} />
								</Tooltip>
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'>
				<strong>Mensaje inicial: </strong>
				{r.main_content}
			</p>
		</React.Fragment>
	)

	const handleUpdate = (id, fieldName, value) => {
		updateTicket(id, {
			[fieldName]: value
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleDelete = id => {
		deleteTicket(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={tickets}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!tickets}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ notesModal && (
				<TicketNotesModal
					visible
					onClose={() => setNotesModal(null)}
					ticket={notesModal}
					reload={reload}
				/>
			)}
			{ editEmailModal && (
				<EditTicketEmailModal 
					visible
					onClose={() => setEditEmailModal(null)}
					ticket={editEmailModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}