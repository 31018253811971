import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import { updateFaq } from '../services'
import { handleError } from '../../../helpers'

export default function EditFaqModal({ visible, onClose, faq, reload }) {
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors } } = useForm({ 
		defaultValues: {
			question: faq.question,
			answer: faq.answer,
		} 
	})

	const onSubmit = values => {
		setLoading(true)
		updateFaq(faq.id, values)
			.then(res => {
				message.success(res.data.message)
				onClose()
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Pregunta Frecuente</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className='form-group'>
							<Form.Label>Pregunta Frecuente <span className='text-primary'>*</span></Form.Label>
							<Form.Control 
								{...register('question', { required: true })}
								placeholder='Escriba aquí...'
							/>
							{ errors.question && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group className='form-group'>
							<Form.Label>Respuesta <span className='text-primary'>*</span></Form.Label>
							<Form.Control 
								as="textarea"
								{...register('answer', {})}
								placeholder='Escriba aquí...'
								style={{ height: '100px' }}
							/>
							{ errors.answer && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Button color='primary' type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Actualizar Registro
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}