import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import { storeUser } from '../services'
import { handleError } from '../../../helpers'

export default function NewUserModal({ visible, onClose, user, reload }) {
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors } } = useForm()

	const onSubmit = values => {
		setLoading(true)
		storeUser(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Usuario Nuevo</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className='form-group'>
							<Form.Label>Nombre Completo <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								{...register('name', { required:true })}
								placeholder='Escriba aquí...'
							/>
							{ errors.name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group className='form-group'>
							<Form.Label>Documento <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								{...register('document', { required:true })}
								placeholder='Escriba aquí...'
								type="number"
							/>
							{ errors.document && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group className='form-group'>
							<Form.Label>Rol <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								as="select"
								{...register('role', { required:true })}
							>
								<option value="">:: Seleccionar una opción ::</option>
								<option value="admin">Administrador</option>
								<option value="jefatura">Jefatura</option>
							</Form.Control>
							{ errors.role && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Row>
							<Form.Group as={Col} className='form-group'>
								<Form.Label>Contraseña <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									{...register('password', { required:true })}
									placeholder='Escriba aquí...'
									type="password"
								/>
								{ errors.password && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
							</Form.Group>
							<Form.Group as={Col} className='form-group'>
								<Form.Label>Confirmar Contraseña <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									{...register('password_confirmation', { required:true })}
									placeholder='Escriba aquí...'
									type="password"
								/>
								{ errors.password_confirmation && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
							</Form.Group>
						</Row>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Agregar Usuario Nuevo
						</Button>						
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}