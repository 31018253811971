import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import InvoicesTable from './partials/InvoicesTable'

import { handleError, parsePagination } from '../../helpers'
import { getInvoices } from './services'

export default function InvoicesList() {
	const [invoices, setInvoices] = useState(null)
	const [filterType, setFilterType] = useState('supplier.name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!invoices && getInvoices({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'supplier',
			sort: '-admitted_at'
		})
			.then(res => {
				setInvoices(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ invoices, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Facturas Radicadas"
				subtitle="Listado de facturas de proveedores"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setInvoices(null)}
				options={[
					{ label: 'Buscar por razón social', value: 'supplier.name' },
					{ label: 'Buscar por NIT', value: 'supplier.document' },
					{ label: 'Buscar en concepto', value: 'description' },
				]}
				scopes={[
					{ label: 'Facturas Admitidas', value: 'pendding' },
					{ label: 'Facturas Pagadas', value: 'paid' },
				]}
			/>
			<Card>
				<CardBody>
					<InvoicesTable 
						invoices={invoices}
						reload={() => setInvoices(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}