import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Descriptions, Divider, Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faLockOpen, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import Pagination from '../../../components/Pagination'
import SupplierAccessModal from './SupplierAccessModal'
import { updateSupplier } from '../services'
import { handleError } from '../../../helpers'
import { getSuppliersPortalUri } from '../../../services/springCenfApi'

export default function SuppliersTable({ suppliers, reload, pagination, setPagination }) {
	const currentUser = useSelector(state => state.auth.user)
	const [accessModal, setAccessModal] = useState(null)
	
	const columns = [
		{
			title: 'Consecutivo Interno',
			dataIndex: 'sku',
		},
		{
			title: 'Razón Social',
			dataIndex: 'name',
		},
		{
			title: 'NIT',
			dataIndex: 'document',
		},
		{
			title: 'Ciudad',
			dataIndex: 'city',
		},
		{
			title: 'Fecha Vinculación',
			dataIndex: 'joined_at',
		},
		{
			title: 'Estado',
			width: '160px',
			dataIndex: 'status',
			render: (t,r) => (
				<React.Fragment>
					<Badge color={t.color}>{t.label}</Badge>
					<Divider type='vertical' />
					<Badge color={(r.username && r.status.key === 'active') ? 'info' : 'dark'}>
						{ (r.username && r.status.key === 'active') ? 'Con Acceso' : 'Sin Acceso' }
					</Badge>
				</React.Fragment>
			)
		},
		{
			title: 'Acciones',
			width: '100px',
			render: (t, r) => (				
				<React.Fragment>
					{ r.username && r.status.key === 'active' && (
						<React.Fragment>
							<Tooltip title="Acceder como Proveedor" className='text-link'>
								<FontAwesomeIcon icon={faDoorOpen} onClick={() => onAccessAsSupplier(r)} />
							</Tooltip>
							<Divider type="vertical" />
						</React.Fragment>
					)}
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Tooltip title="Acceso al Portal" className='text-link'>
								<FontAwesomeIcon icon={faLockOpen} onClick={() => setAccessModal(r)} />
							</Tooltip>
							<Divider type="vertical" />
							{ !r.disabled_at ? (
								<Popconfirm
									title="¿Desea desactivar acceso del proveedor?"
									okText="Desactivar"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => handleToggle(r)}
								>
									<Tooltip title="Desactivar" className='text-link'>
										<FontAwesomeIcon icon={faToggleOn} />
									</Tooltip>
								</Popconfirm>
							) : (
								<Popconfirm
									title="¿Desea activar acceso del proveedor?"
									okText="Activar"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => handleToggle(r)}
								>
									<Tooltip title="Activar" className='text-link'>
										<FontAwesomeIcon icon={faToggleOff} />
									</Tooltip>
								</Popconfirm>
							)}
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<Descriptions column={2} size='small' bordered>
				<Descriptions.Item span={2} label="Persona de Contacto">{r.contact_name}</Descriptions.Item>
				<Descriptions.Item label="Telefono de Contacto">{r.contact_mobile}</Descriptions.Item>
				<Descriptions.Item label="Correo de Contacto">{r.contact_email}</Descriptions.Item>
				<Descriptions.Item span={2} label="Dirección Ppal">{r.main_address}</Descriptions.Item>
			</Descriptions>
		</React.Fragment>
	)

	const handleToggle = supplier => {
		updateSupplier(supplier.id, {
			disabled_at: supplier.disabled_at ? '' : moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const onAccessAsSupplier = supplier => {
		window.open(`${getSuppliersPortalUri()}/#/auth/impersonation/${supplier.id}/${currentUser.id}`)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={suppliers}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!suppliers}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ accessModal && (
				<SupplierAccessModal 
					visible
					onClose={() => setAccessModal(null)} 
					reload={reload}
					supplier={accessModal}
				/>
			)}
		</React.Fragment>
	)
}