import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import ActivityLogsTable from './partials/ActivityLogsTable'

import { handleError, parsePagination } from '../../helpers'
import { getActivityLogs } from './services'

export default function ActivityLogsList() {
	const [activityLogs, setActivityLogs] = useState(null)
	const [filterType, setFilterType] = useState('description')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!activityLogs && getActivityLogs({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'causer,subject',
			sort: '-created_at'
		})
			.then(res => {
				setActivityLogs(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ activityLogs, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Historial de Actividad"
				subtitle="Acciones realizadas en plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setActivityLogs(null)}
				options={[
					{ label: 'Buscar en descripción', value: 'description' },
				]}
			/>
			<Card>
				<CardBody>
					<ActivityLogsTable 
						activityLogs={activityLogs}
						reload={() => setActivityLogs(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}