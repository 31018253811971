import React, { useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import { message } from 'antd'

import { storeFaq } from '../services'
import { handleError } from '../../../helpers'

export default function NewFaqForm({ reload }) {
	const { handleSubmit, register, formState: { errors }, reset } = useForm({})
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeFaq(values)
			.then(res => {
				message.success(res.data.message)
				setLoading(false)
				reset()
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group className='form-group'>
					<Form.Label>Pregunta Frecuente <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						{...register('question', { required: true })}
						placeholder='Escriba aquí...'
					/>
					{ errors.question && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group'>
					<Form.Label>Respuesta <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						as="textarea"
						{...register('answer', {})}
						placeholder='Escriba aquí...'
						style={{ height: '150px' }}
					/>
					{ errors.answer && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Button color='primary' type="submit" disabled={loading}>
					{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
					Crear Nuevo Registro
				</Button>
			</Form>
		</React.Fragment>
	)
}