import React from 'react'
import { Form } from 'react-bootstrap'

export default function TicketHintForm({ register, errors, onlyEditable }) {
	return (
		<React.Fragment>
			<Form.Group className='form-group'>
				<Form.Label>Orden/Posición <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					type="number"
					{...register('order', { required: true })}
				/>
				{ errors.order && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Contenido <span className='text-primary'>*</span></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('description', { required: true })}
					style={{ height: '100px' }}
				/>
				{ errors.description && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}