import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import NewFaqForm from './partials/NewFaqForm'
import FaqsTable from './partials/FaqsTable'

import { getFaqs } from './services'
import { handleError } from '../../helpers'

export default function FaqsList() {
	const [faqs, setFaqs] = useState(null)
	const [filterType, setFilterType] = useState('question')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')

	useEffect(() => {
		!faqs && getFaqs({
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			sort: '-created_at',
		})
			.then(res => setFaqs(res.data.data))
			.catch(error => handleError(error))
	}, [ faqs, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<Row>
				<Col md="4">
					<Card>
						<CardBody>
							<CardTitle>Agregar Pregunta Frecuente</CardTitle>
							<CardSubtitle>Crear nuevo registro</CardSubtitle>
							<hr className='mt-10' />
							<NewFaqForm reload={() => setFaqs(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col>
					<ListTopBar 
						title="Preguntas Frecuentes"
						subtitle="Listado visible en módulo de soporte"
						filterType={filterType}
						setFilterType={setFilterType}
						filterValue={filterValue}
						setFilterValue={setFilterValue}
						filterScope={filterScope}
						setFilterScope={setFilterScope}
						reload={() => setFaqs(null)}
						options={[
							{ label: 'Buscar en pregunta', value: 'question' },
							{ label: 'Buscar en respuesta', value: 'answer' },
						]}
						scopes={[
							{ label: 'Preguntas Activas/Visibles', value: 'active' },
							{ label: 'Preguntas Inactivas/Ocultas', value: 'disabled' },
						]}
						className="mb-10"
					/>
					<Card>
						<CardBody>
							<FaqsTable 
								faqs={faqs}
								reload={() => setFaqs(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}