import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import NewTicketTypeForm from './partials/NewTicketTypeForm'
import TicketTypesTable from './partials/TicketTypesTable'

import { getTicketTypes } from './services'
import { handleError } from '../../helpers'

export default function TicketTypesList() {
	const [ticketTypes, setTicketTypes] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')

	useEffect(() => {
		!ticketTypes && getTicketTypes({
			[`filter[${filterType}]`]: filterValue,
		 	'filter[group_slug]': filterScope,
			 include: 'ticketsCount',
			sort: '-created_at',
		})
			.then(res => setTicketTypes(res.data.data))
			.catch(error => handleError(error))
	}, [ ticketTypes, filterType, filterValue, filterScope ])

	const ticketTypeGroups = [
		{ label: 'Modulo de Facturas', value: 'invoices' },
		{ label: 'Modulo de Pagos Recibidos', value: 'payments' },
		{ label: 'Modulo de Pagos Programados', value: 'futurePayments' },
		{ label: 'Modulo de Certificados', value: 'certificates' },
		{ label: 'Modulo de Mi Cuenta', value: 'myProfile' },
	]

	return (
		<React.Fragment>
			<Row>
				<Col md="4">
					<Card>
						<CardBody>
							<CardTitle>Agregar Tipo de Solicitud</CardTitle>
							<CardSubtitle>Crear nuevo registro</CardSubtitle>
							<hr className='mt-10' />
							<NewTicketTypeForm 
								reload={() => setTicketTypes(null)}
								ticketTypeGroups={ticketTypeGroups}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<ListTopBar 
						title="Tipos de Solicitudes"
						subtitle="Listado general de la plataforma"
						filterType={filterType}
						setFilterType={setFilterType}
						filterValue={filterValue}
						setFilterValue={setFilterValue}
						filterScope={filterScope}
						setFilterScope={setFilterScope}
						reload={() => setTicketTypes(null)}
						options={[
							{ label: 'Buscar por nombre', value: 'name' },
						]}
						scopes={ticketTypeGroups}
						scopeLabel="Categoría"
						className="mb-10"
					/>
					<Card>
						<CardBody>
							<TicketTypesTable 
								ticketTypes={ticketTypes}
								reload={() => setTicketTypes(null)}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}