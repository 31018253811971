import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Badge } from 'reactstrap'
import { Divider, Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditUserModal from './EditUserModal'

import { updateUser } from '../services'
import { handleError } from '../../../helpers'

export default function UsersTable({ users, reload }) {	
	const currentUser = useSelector(state => state.auth.user)
	const [editUser, setEditUser] = useState(null)

	const columns = [
		{
			title: 'Nombre Completo',
			dataIndex: 'name',
		},
		{
			title: 'Documento',
			dataIndex: 'document',
		},
		{
			title: 'Rol',
			dataIndex: 'role_name',
		},
		{
			title: 'Fecha Vinculación',
			dataIndex: 'created_at',
			render: t => `${t} (${moment(t).fromNow()})`
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: (t,r) => (
				<React.Fragment>
					<Badge color={t.color}>{t.label}</Badge>
				</React.Fragment>
			)
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Tooltip title="Actualizar" className='text-link' onClick={() => setEditUser(r)}>
								<FontAwesomeIcon icon={faEdit} />{" "}
								Editar
							</Tooltip>
							<Divider type="vertical" />
							{ !r.disabled_at ? (
								<Popconfirm
									title="¿Desea desactivar acceso del usuario?"
									okText="Desactivar"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => handleToggle(r)}
								>
									<Tooltip title="Desactivar" className='text-link'>
										<FontAwesomeIcon icon={faToggleOn} />{" "}
										Desactivar
									</Tooltip>
								</Popconfirm>
							) : (
								<Popconfirm
									title="¿Desea activar acceso del usuario?"
									okText="Activar"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => handleToggle(r)}
								>
									<Tooltip title="Activar" className='text-link'>
										<FontAwesomeIcon icon={faToggleOff} />{" "}
										Activar
									</Tooltip>
								</Popconfirm>
							)}
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const handleToggle = user => {
		updateUser(user.id, {
			disabled_at: user.disabled_at ? '' : moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={users}
				rowKey={r => r.id}
				columns={columns}
				loading={!users}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			{ editUser && (
				<EditUserModal 
					visible
					onClose={() => setEditUser(null)}
					user={editUser}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}