import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import SuppliersTable from './partials/SuppliersTable'

import { handleError, parsePagination } from '../../helpers'
import { getSuppliers } from './services'

export default function SuppliersList() {
	const [suppliers, setSuppliers] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!suppliers && getSuppliers({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			sort: '-created_at'
		})
			.then(res => {
				setSuppliers(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ suppliers, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Directorio de Proveedores"
				subtitle="Listado de proveedores Spring"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setSuppliers(null)}
				options={[
					{ label: 'Buscar en razón social', value: 'name' },
					{ label: 'Buscar en NIT', value: 'document' },
					{ label: 'Buscar en ciudad', value: 'city' },
				]}
				scopes={[
					{ label: 'Proveedores Activos', value: 'active' },
					{ label: 'Proveedores Inactivos', value: 'disabled' },
				]}
			/>
			<Card>
				<CardBody>
					<SuppliersTable 
						suppliers={suppliers}
						reload={() => setSuppliers(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}