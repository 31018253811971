import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import FuturePaymentsTable from './partials/FuturePaymentsTable'

import { handleError, parsePagination } from '../../helpers'
import { getFuturePayments } from './services'

export default function FuturePaymentsList() {	
	const [futurePayments, setFuturePayments] = useState(null)
	const [filterType, setFilterType] = useState('supplier.name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!futurePayments && getFuturePayments({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'supplier',
			sort: '-created_at'
		})
			.then(res => {
				setFuturePayments(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ futurePayments, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Pagos Programados"
				subtitle="Listado de pagos programados a proveedores"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setFuturePayments(null)}
				options={[
					{ label: 'Buscar por razón social', value: 'supplier.name' },
					{ label: 'Buscar por NIT', value: 'supplier.document' },
					{ label: 'Buscar por fecha (YYYY-MM-DD)', value: 'schedule_at' },
				]}
				scopes={[
					{ label: 'Programaciones Activas', value: 'active' },
					{ label: 'Programaciones Inactivas', value: 'inactive' },
					{ label: 'Programaciones Anuladas', value: 'voided' },
				]}
			/>
			<Card>
				<CardBody>
					<FuturePaymentsTable 
						futurePayments={futurePayments}
						reload={() => setFuturePayments(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}