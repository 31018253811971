import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faCogs, faLifeRing, faQuestionCircle, faUsers } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function SettingsDashboard() {

	const items = [
		{
			title: 'Usuarios Administrador',
			subtitle: 'Administre los usuarios con acceso al administrador',
			icon: faUsers,
			linkTo: '/users'
		},
		{
			title: "Preguntas Frecuentes",
			subtitle: "Listado de preguntas frecuentes de soporte",
			icon: faQuestionCircle,
			linkTo: "/faqs",
		},
		{
			title: "Tipos de Solicitudes de Soporte",
			subtitle: "Categorias de solicitudes de soporte aceptados",
			icon: faLifeRing,
			linkTo: "/ticket_types",
		},
		{
			title: "Otros Ajustes del Sistema",
			subtitle: "Ajustes adicionales del sistema",
			icon: faCogs,
			linkTo: "/settings/admin",
		}
	]

	return (
		<React.Fragment>
			<PageTitle title="Ajustes Administrador" subtitle="Plataforma CENF Spring." />
			<Row>
				{ items.map(item => (
					<Col key={item.title} sm="12" md="6" lg="4" className="mb-10">
						<MenuWidget 
							title={item.title}
							subtitle={item.subtitle}
							icon={item.icon}
							linkTo={item.linkTo}
						/>
					</Col>
				)) }
			</Row>
		</React.Fragment>
	)
}