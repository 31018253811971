import React, { useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import { message } from 'antd'

import { storeTicketType } from '../services'
import { handleError } from '../../../helpers'

export default function NewTicketTypeForm({ reload, ticketTypeGroups }) {
	const { handleSubmit, register, formState: { errors }, reset } = useForm({})
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeTicketType(values)
			.then(res => {
				message.success(res.data.message)
				setLoading(false)
				reset()
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group className='form-group'>
					<Form.Label>Categoría <span className='text-primary'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('group_slug', { required:true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						{ ticketTypeGroups.map(type => (
							<option key={type.value} value={type.value}>
								{type.label}
							</option>
						)) }
					</Form.Control>
					{ errors.group_slug && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group'>
					<Form.Label>Tipo de Solicitud <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						{...register('name', { required: true })}
						placeholder='Escriba aquí...'
					/>
					{ errors.name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text>}
				</Form.Group>
				<Form.Group className='form-group'>
					<Form.Label>Correo de Notificación <span className='text-primary'>*</span></Form.Label>
					<Form.Control 
						{...register('notification_email', { required:true })}
						placeholder='Escriba aquí...'
					/>
					{ errors.notification_email && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Button color='primary' type="submit" disabled={loading}>
					{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
					Crear Nuevo Registro
				</Button>
			</Form>
		</React.Fragment>
	)
}