import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import { updateSupplier } from '../services'
import { handleError } from '../../../helpers'

export default function SupplierAccessModal({ visible, onClose, reload, supplier }) {
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: { 
			username: supplier.username
		}
	})

	const onSubmit = values => {
		setLoading(true)
		if(values.username === supplier.username) delete values.username
		updateSupplier(supplier.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Acceso Portal Proveedores</ModalHeader>
				<ModalBody>
					<p className='mb-5'>Acceso del Proveedor {supplier.name} ({supplier.sku})</p>
					<p>NIT del Proveedor: <strong>{supplier.document}</strong></p>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className='form-group'>
							<Form.Label>Nombre de Usuario <span className='text-primary'>*</span></Form.Label>
							<Form.Control 
								{...register('username', { required:true })}
								placeholder='Escriba el nombre de usuario del proveedor'
							/>
							{ errors.username && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group className='form-group'>
							<Form.Label>Contraseña <span className='text-primary'>*</span></Form.Label>
							<Form.Control 
								{...register('password', { required:true })}
								placeholder='Escriba una contraseña'
								type="password"
							/>
							{ errors.password && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group className='form-group'>
							<Form.Label>Confirmar Contraseña <span className='text-primary'>*</span></Form.Label>
							<Form.Control 
								{...register('password_confirmation', { required:true })}
								placeholder='Escriba de nuevo la contraseña'
								type="password"
							/>
							{ errors.password_confirmation && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Actualizar Acceso del Proveedor
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}