import React from "react"
import {useSelector} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import MainLayout from "./layouts/MainLayout"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'
import Tickets from "./containers/Tickets"
import Invoices from "./containers/Invoices"
import FuturePayments from "./containers/FuturePayments"
import ActivityLogs from "./containers/ActivityLogs"
import Suppliers from "./containers/Suppliers"
import Settings from "./containers/Settings"
import TicketTypes from "./containers/TicketTypes"
import Faqs from "./containers/Faqs"
import Users from "./containers/Users"

export default function App() {	
	const token = useSelector(state => state.auth.token)
	
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/" to="/tickets" />

				<Route path="/auth" component={Authentication} />
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>
					<Route path="/invoices" component={Invoices} />
					<Route path="/future_payments" component={FuturePayments} />
					<Route path="/tickets" component={Tickets} />
					<Route path="/suppliers" component={Suppliers} />
					<Route path="/activity_logs" component={ActivityLogs} />
					<Route path="/settings" component={Settings} />
					<Route path="/ticket_types" component={TicketTypes} />
					<Route path="/faqs" component={Faqs} />
					<Route path="/users" component={Users} />
					
					<Route path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</React.Fragment>
	);	
}