import React from 'react'
import { Empty, Table } from 'antd'

import Pagination from '../../../components/Pagination'
import moment from 'moment'

export default function ActivityLogsTable({ activityLogs, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Fecha',
			dataIndex: 'created_at',
			width: '145px',
			render: t => `${t} (${moment(t).fromNow()})`
		},
		{
			title: 'Causante',
			width: '290px',
			dataIndex: 'causer',
			render: (t, r) => t ? `[${r.causer_type.includes('User') ? 'Usuario' : 'Proveedor'}] ${t.name} (${t.document})` : <em>Sin especificar</em>
		},
		{
			title: 'Actividad',
			dataIndex: 'description',
		},
		{
			title: 'Asunto/Objetivo',
			dataIndex: 'subject',
			width: '320px',
			render: t => t ? t.subject_for_log : 'Registro eliminado'
		},
	]

	const expandedRowRender = r => (
		<React.Fragment>
			{ (r.properties && r.properties.attributes) ? (
				<table className='table sm'>
					<thead>
						<tr>
							<th>Atributo</th>
							<th>Valor Antiguo</th>
							<th>Valor Nuevo</th>
						</tr>
					</thead>
					<tbody>
						{ Object.keys(r.properties.attributes).map((attribute, i) => (
							<tr key={`${r.id}-${i}`}>
								<td>{attribute}</td>
								{ r.properties.old ? (
									<td>{r.properties.old[attribute] ? r.properties.old[attribute] : <em>Vacio</em>}</td>
								) : (
									<td><em>No aplica</em></td>
								)}
								<td>{r.properties.attributes[attribute] ? r.properties.attributes[attribute] : <em>Vacio</em>}</td>
							</tr>
						)) }
					</tbody>
				</table>
			) : (
				<em>Sin detalles adicionales</em>
			)}
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={activityLogs}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!activityLogs}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}