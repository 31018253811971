import React from 'react'
import { Badge } from 'reactstrap'
import { Descriptions, Empty, Table } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import Pagination from '../../../components/Pagination'
import { number } from '../../../helpers'

export default function InvoicesTable({ invoices, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Fecha de Radicación',
			dataIndex: 'admitted_at',
			render: t => `${t} (${moment(t).fromNow()})`
		},
		{
			title: 'Proveedor',
			dataIndex: 'supplier',
			render: r => r.name
		},
		{
			title: 'NIT',
			dataIndex: 'supplier',
			render: r => r.document
		},
		{
			title: 'Consecutivo',
			dataIndex: 'consecutive',
		},
		{
			title: 'Concepto',
			dataIndex: 'description',
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<Descriptions bordered size='small'>
				<Descriptions.Item label="Consecutivo Interno">{r.sku}</Descriptions.Item>
				<Descriptions.Item label="Valor Total">${number(r.total_value)} COP</Descriptions.Item>
				<Descriptions.Item label="Fecha de Emisión">
					{r.sent_at} ({moment(r.sent_at).fromNow()})
				</Descriptions.Item>
				<Descriptions.Item label="Fecha Vencimiento">
					{r.expire_at} ({moment(r.expire_at).fromNow()})
				</Descriptions.Item>
			</Descriptions>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={invoices}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!invoices}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}