import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { Divider, Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditFaqModal from './EditFaqModal'

import { deleteFaq, updateFaq } from '../services'
import { handleError } from '../../../helpers'

export default function FaqsTable({ faqs, reload }) {
	const [editModal, setEditModal] = useState(null)

	const columns = [
		{
			title: 'Pregunta',
			dataIndex: 'question'
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			width: '120px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar" className='text-link'>
						<FontAwesomeIcon icon={faEdit} onClick={() => setEditModal(r)} />
					</Tooltip>
					<Divider type="vertical" />
					<Popconfirm
						title={r.disabled_at ? 'Al activar quedará visible esta pregunta' : 'Al desactivar quedará oculta este pregunta'}
						okText={r.disabled_at ? 'Activar' : 'Desactivar'}
						cancelText="Cancelar"
						okButtonProps={{ danger:true }}
						onConfirm={() => handleToggle(r)}
					>
						<Tooltip 
							title={r.disabled_at ? 'Activar' : 'Desactivar'} 
							className='text-link'
							placement='bottom'
						>
							<FontAwesomeIcon 
								icon={r.disabled_at ? faToggleOff : faToggleOn}
							/>
						</Tooltip>
					</Popconfirm>
					<Divider type="vertical" />
					<Popconfirm
						title="Esta acción no se puede revertir"
						okText="Eliminar"
						cancelText="Cancelar"
						okButtonProps={{ danger:true }}
						onConfirm={() => handleDelete(r.id)}
					>
						<Tooltip 
							title="Eliminar" 
							className='text-link'
							placement='bottom'
						>
							<FontAwesomeIcon icon={faTrash} />
						</Tooltip>
					</Popconfirm>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p><strong>Respuesta: </strong>{r.answer}</p>
		</React.Fragment>
	)

	const handleDelete = id => {
		deleteFaq(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleToggle = r => {
		updateFaq(r.id, {
			disabled_at: r.disabled_at ? '' : moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Table 
				dataSource={faqs}
				columns={columns}
				rowKey={r => r.id}
				loading={!faqs}
				size='small'
				locale={{ 
					emptyText: (
						<Empty 
							description="No se encontraron registros" 
							imageStyle={{ height:'80px' }}
						/>
					) 
				}}
				expandable={{ expandedRowRender }}
			/>
			{ editModal && (
				<EditFaqModal 
					visible
					onClose={() => setEditModal(null)}
					faq={editModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}