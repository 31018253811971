import springCenfApi from '../../services/springCenfApi'

export const getFuturePayments = params => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get('/future_payments', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getFuturePayment = (id, params) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get(`/future_payments/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeFuturePayment = data => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.post('/future_payments', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateFuturePayment = (id, data) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.put(`/future_payments/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteFuturePayment = id => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.delete(`/future_payments/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)