import springCenfApi from '../../services/springCenfApi'

export const getInvoices = params => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get('/invoices', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getInvoice = (id, params) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get(`/invoices/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeInvoice = data => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.post('/invoices', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateInvoice = (id, data) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.put(`/invoices/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteInvoice = id => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.delete(`/invoices/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)