import springCenfApi from '../../services/springCenfApi'

export const getSuppliers = params => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get('/suppliers', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getSupplier = (id, params) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get(`/suppliers/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSupplier = data => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.post('/suppliers', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSupplier = (id, data) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.put(`/suppliers/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteSupplier = id => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.delete(`/suppliers/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)