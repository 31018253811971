import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import { Button, Card, CardBody } from 'reactstrap'
import { message } from 'antd'

import { getSettings, updateSetting } from './services'
import { handleError } from '../../helpers'

export default function AdminSettings() {
    const [settings, setSettings] = useState(null)
    const { register, handleSubmit } = useForm({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      !settings && getSettings()
        .then(res => setSettings(res.data.data))
        .catch(error => handleError(error))
    }, [ settings ])

    if(!settings) 
        return <Spinner animation='border' size="sm" />

    const onSubmit = values => {        
        for (const [key, value] of Object.entries(values)) {
            setLoading(true)
            updateSetting(parseInt(key), { value })
                .then(res => message.success(res.data.message))
                .catch(error => handleError(error))
                .finally(() => setLoading(false))
        }
    }

	return (
		<React.Fragment>
            <Row>
                <Col sm="6" md="4">
                    <Card>
                        <CardBody>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                { settings.map(setting => (
                                    <Form.Group className='form-group' key={setting.id} >
                                        <Form.Label>{setting.label}</Form.Label>
                                        <Form.Control 
                                            {...register(setting.id.toString(), {})}
                                            defaultValue={setting.value}
                                            placeholder='Escriba aquí...'
                                            as="textarea"
                                            style={{ height: '70px' }}
                                        />
                                        <Form.Text>{setting.hint}</Form.Text>
                                    </Form.Group>
                                )) }
                                <Button color="primary" type="submit" disabled={loading}>
                                    { loading && <Spinner animation='border' size="sm" /> }{" "}
                                    Actualizar Ajustes
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
		</React.Fragment>
	)
}
