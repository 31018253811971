import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import TicketHintForm from './TicketHintForm'

import { updateTicketHint } from '../services'
import { handleError } from '../../../helpers'

export default function EditTicketHintModal({ visible, onClose, ticketHint, reload }) {
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			order: ticketHint.order,
			description: ticketHint.description,
		}
	})

	const onSubmit = values => {
		setLoading(true)
		updateTicketHint(ticketHint.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Respuesta Predefinida</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<TicketHintForm 
							register={register}
							errors={errors}
							onlyEditable
						/>
						<Button color='primary' type='submit' disabled={loading}>
							{ loading && <Spinner animation='border' className='mr-5' size="sm" /> }
							Actualizar Registro
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}