import springCenfApi from '../../services/springCenfApi'

export const getSettings = params => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get('/settings', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSetting = (id, data) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.put(`/settings/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)