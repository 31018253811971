import React, { useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import { updateTicket } from '../services'
import { handleError } from '../../../helpers'

export default function EditTicketEmailModal({ visible, onClose, ticket, reload }) {
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register } = useForm({
		defaultValues: {
			custom_notification_email: ticket.custom_notification_email,
			is_new_custom_email: 1,
		}
	})

	const onSubmit = values => {
		updateTicket(ticket.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Correo de Notificación</ModalHeader>
				<ModalBody>
					<p>Por defecto todas las notificaciones de esta solicitud van dirigidas a <strong>{ticket.custom_notification_email ? ticket.custom_notification_email : ticket.ticket_type.notification_email}</strong>, si desea cambiarlo diligencie el siguiente formulario:</p>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className='form-group'>
							<Form.Label>Correo Notificación Spring Personalizado <small>(opcional)</small></Form.Label>
							<Form.Control 
								{...register('custom_notification_email', {})}
								placeholder='Escriba un correo válido'
							/>
						</Form.Group>
						<Button color='primary' type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Actualizar Correo de Notificación.
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}