import springCenfApi from '../../services/springCenfApi'

export const getUsers = params => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get('/users', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getUser = (id, params) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get(`/users/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeUser = data => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.post('/users', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateUser = (id, data) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.put(`/users/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteUser = id => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.delete(`/users/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)