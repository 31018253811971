import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'

import ListTopBar from '../../components/ListTopBar'
import TicketsTable from './partials/TicketsTable'

import { handleError, parsePagination } from '../../helpers'
import { getTickets } from './services'

export default function TicketsList() {
	const [tickets, setTickets] = useState(null)
	const [filterType, setFilterType] = useState('supplier.document')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!tickets && getTickets({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'supplier,ticketType,ticketNotesCount',
			sort: '-created_at'
		})
			.then(res => {
				setTickets(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ tickets, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Solicitudes de Soporte"
				subtitle="Listado de solicitudes de proveedores"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setTickets(null)}
				options={[
					{ label: 'Buscar por NIT', value: 'supplier.document' },
					{ label: 'Buscar por Razón Social', value: 'supplier.name' },
					{ label: 'Buscar por ID Ticket', value: 'id' },
				]}
				scopes={[
					{ label: 'Solicitudes Activas', value: 'active' },
					{ label: 'Solicitudes Resueltos', value: 'resolved' },
					{ label: 'Solicitudes Cancelados', value: 'voided' },
				]}
				ctaText={<FontAwesomeIcon icon={faRefresh} spin={!tickets} />}
				handleCtaClick={() => setTickets(null)}
			/>
			<Card>
				<CardBody>
					<TicketsTable 
						tickets={tickets}
						reload={() => setTickets(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}