import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Divider, Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import NewTicketHintModal from './NewTicketHintModal'
import EditTicketHintModal from './EditTicketHintModal'

import { deleteTicketHint, getTicketHints } from '../services'
import { handleError } from '../../../helpers'

export default function TicketHintsModal({ visible, onClose, ticketType }) {
	const [ticketHints, setTicketHints] = useState(null)
	const [editModal, setEditModal] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!ticketHints && visible && getTicketHints({
			'filter[ticket_type_id]': ticketType.id,
			sort: 'order,-created_at'
		})
			.then(res => setTicketHints(res.data.data))
			.catch(error => handleError(error))
	}, [ ticketHints, ticketType, visible ])

	const columns = [
		{
			title: 'Orden',
			dataIndex: 'order',
			width: '90px'
		},
		{
			title: 'Contenido',
			dataIndex: 'description'
		},
		{
			title: 'Acciones',
			width: '170px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar" className='text-link' onClick={() => setEditModal(r)}>
						<FontAwesomeIcon icon={faEdit} />{" "}
						Editar
					</Tooltip>
					<Divider type='vertical' />
					<Popconfirm
						title="Esta acción no se puede revertir"
						okText="Eliminar"
						cancelText="Cancelar"
						okButtonProps={{ danger:true }}
						onConfirm={() => handleDelete(r.id)}
					>
						<Tooltip title="Eliminar" className='text-link'>
							<FontAwesomeIcon icon={faTrash} />{" "}
							Eliminar
						</Tooltip>
					</Popconfirm>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteTicketHint(id)
			.then(res => {
				message.success(res.data.message)
				setTicketHints(null)
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size='lg'>
				<ModalHeader toggle={onClose}>Respuestas Predefinidas</ModalHeader>
				<ModalBody>
					<p><strong>Tipo de solicitud: </strong>{ticketType.name}</p>
					<Table 
						dataSource={ticketHints}
						loading={!ticketHints}
						rowKey={r => r.id}
						size="small"
						columns={columns}
						locale={{ 
							emptyText: (
								<Empty 
									description="No se encontraron respuestas predefinidas" 
									imageStyle={{ height: '70px' }}
								/>
							) 
						}}
					/>
					<Button 
						color='primary' 
						onClick={() => setShowNewModal(true)}
						outline
						className='mt-10' 
					>
						Agregar Respuesta Predefinida
					</Button>
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewTicketHintModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setTicketHints(null)}
					ticketType={ticketType}
				/>
			)}
			{ editModal && (
				<EditTicketHintModal 
					visible
					onClose={() => setEditModal(null)}
					ticketHint={editModal}
					reload={() => setTicketHints(null)}
				/>
			)}
		</React.Fragment>
	)
}