import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { Divider, Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import TicketHintsModal from '../../TicketHints/partials/TicketHintsModal'

import { deleteTicketType, updateTicketType } from '../services'
import { handleError } from '../../../helpers'

export default function TicketTypesTable({ ticketTypes, reload }) {
	const [hintsModal, setHintsModal] = useState(null)

	const columns = [
		{
			title: 'Tipo',
			dataIndex: 'name'
		},
		{
			title: 'Categoría',
			dataIndex: 'group_name',
		},
		{
			title: 'Correo de Notificación',
			dataIndex: 'notification_email'
		},
		{
			title: 'Estado',
			width: '80px',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			width: '100px',
			render: (t, r) => (
				<React.Fragment>
					<Popconfirm
						title={r.disabled_at ? 'Al activar quedará visible este tipo de solicitud' : 'Al desactivar quedará oculto este tipo de solicitud'}
						okText={r.disabled_at ? 'Activar' : 'Desactivar'}
						cancelText="Cancelar"
						okButtonProps={{ danger:true }}
						onConfirm={() => handleToggle(r)}
					>
						<Tooltip 
							title={r.disabled_at ? 'Activar' : 'Desactivar'} 
							className='text-link'
							placement='bottom'
						>
							<FontAwesomeIcon 
								icon={r.disabled_at ? faToggleOff : faToggleOn}
							/>
						</Tooltip>
					</Popconfirm>
					<Divider type="vertical" />
					<Tooltip title="Resp. Predefinidas" className='text-link'>
						<FontAwesomeIcon icon={faQuestionCircle} onClick={() => setHintsModal(r)} />
					</Tooltip>
					{ r.tickets_count === 0 && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm
								title="Esta acción no se puede revertir"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDelete(r.id)}
							>
								<Tooltip 
									title="Eliminar" 
									className='text-link'
									placement='bottom'
								>
									<FontAwesomeIcon icon={faTrash} />
								</Tooltip>
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteTicketType(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleToggle = r => {
		updateTicketType(r.id, {
			disabled_at: r.disabled_at ? '' : moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Table 
				dataSource={ticketTypes}
				columns={columns}
				rowKey={r => r.id}
				loading={!ticketTypes}
				size='small'
				locale={{ 
					emptyText: (
						<Empty 
							description="No se encontraron registros" 
							imageStyle={{ height:'80px' }}
						/>
					) 
				}}
			/>
			{ hintsModal && (
				<TicketHintsModal 
					visible
					onClose={() => setHintsModal(null)}
					ticketType={hintsModal}
				/>
			)}
		</React.Fragment>
	)
}