import springCenfApi from '../../services/springCenfApi'

export const getFaqs = params => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get('/faqs', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getFaq = (id, params) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get(`/faqs/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeFaq = data => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.post('/faqs', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateFaq = (id, data) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.put(`/faqs/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteFaq = id => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.delete(`/faqs/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)